@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import "~include-media/dist/_include-media.scss";

$breakpoints: (phone: 320px, tablet: 768px, desktop: 1440px);
$fa-font-path: '@fortawesome/fontawesome-free/webfonts';

#index_container {
  display: flex;
  flex-direction: column;
}

#index_title_container {
  margin-right: 3%;
  padding-bottom: 4%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include media("<tablet") {
    flex-direction: column;
    width: 100%;
  }
  @include media(">=tablet", "<desktop") {
    width:50%;
  }
}


#index_intro_paragraph {
  width: 50%;
  margin-right: 160px;
  margin-top: 50px;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  color: #67696E;
  text-align: left;

  @include media("<tablet"){
    width: 100%;
    margin-top: 0;
  }
}

#index_title {
  font-family: 'Muli', sans-serif;
  margin:auto;
  font-size: 38px;
  font-weight: 900;
  color: black;
  text-align: right;

  @include media("<tablet") {
    width: 100%;
    text-align:left;
  }
}

.index-element {
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
  width: 90%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @include media("<tablet") {
    margin: 0 auto;
    flex-direction: column;
    width: 100%;
  }

  @include media(">=tablet", "<desktop") {
    flex-wrap: wrap;
    width: 100%;
  }
}

.index-element-text-wrap {
  padding-top: 25px;
  padding-right: 2%;
  margin-left: 100px;
  @include media("<tablet") {
    width: 100%;
    margin-left: 0;
  }
  @include media(">=tablet", "<desktop") {
    padding-right: 0;
    margin-left: 0;
    width: 40%;
  }
}

.button_container{
  @include media(">=tablet", "<desktop") {
    display: flex;
    flex-direction: column !important;
  }
}

.button_container a{
  @include media(">=tablet", "<desktop") {
    margin-bottom: 12px;
  }
}

.index-element-text {
  width: 635px;
  margin-right: 0;

  @include media("<tablet") {
    width: 100%;
    margin-left: 0;
    margin-bottom: 15%;
  }
  @include media(">=tablet", "<desktop") {
    width: auto;
  }
}
.banner-element{
  background: #E1052D;
  @include media("<tablet") {
    background: white;

  }

}

.image-index{
  padding: 20px 20px 0 20px;
  margin: 0;
}

#gentejapy{
  margin-bottom: 40px;
  @include media("<tablet") {
    display: none;
  }
}

.index-element-text-bold{
  width: 90%;
  margin-right: 0;
  font-family: 'Muli', sans-serif;
  font-weight: 600;
  font-size: 21px;
  color:white;
  padding-right: 17%;
  margin-left: 7%;

  @include media("<tablet") {
    width: 100%;
    margin-bottom: 15%;
    color:black;
  }

}
#fad_logo{
  width: 71px;
  height: 36px;
  @include media("<tablet"){
  }
}

#maldita_logo{
  width:165px;
  height: 47px;
  margin-top: 10%;
  @include media("<tablet"){
    margin-bottom:15%;
  }
}

#google_logo{
  width:164px;
  height: 35px;
}

#with_support{
  @include media("<tablet"){
    display: none;
  }
}

#project_desc_logos{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color:black;
  padding: 4%;
  padding-right: 17%;
  @include media("<tablet"){
    margin:5%;
    align-items: center;
    background: none;
  }
}

#index-banner{
  width:100%;
}

.header-images-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  @include media("<tablet") {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @include media(">=tablet", "<desktop") {
    flex-wrap: wrap;
  }
}

.header-images{
  width: 138px;
  height: 245px;
  margin: 31px 15px 50px;
  object-fit: contain;
  @include media("<tablet") {
    margin: 0px;
  }
}





#pavaalegre{
  width: 242px;
  height: 190px;
}
